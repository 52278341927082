<template>
  <div class="principal">
    <div v-if="totalization.length == 0">
      <span class="title">Nenhum registro encontrado!</span>
    </div>
    <TableTotalization v-else :totalization="totalization" />
  </div>
</template>
<script>
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading";
import TableTotalization from "@nixweb/nixloc-ui/src/component/shared/TableTotalization";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Totalization",
  components: { Loading, TableTotalization },
  data() {
    return {
      totalization: [],
      urlGet: "/api/v1/cashier/movement/totalization",
      loading: true,
    };
  },

  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getAll(cashierId) {
      this.loading = true;
      let obj = { cashierId };
      let params = { url: this.urlGet, obj: obj };
      this.getApi(params).then((response) => {
        this.totalization = response.content;
        this.loading = false;
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "_closeCashier" || event.name == "movementCashier") {
          this.getAll(event.data.id);
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.tabela-principal {
  font-size: 14px;
  max-width: 200px;
}

.tabela-descricao {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.title-header {
  font-size: 13px;
  padding-bottom: 5px;
  color: #6c757d;
  text-transform: uppercase;
}

.tabela-principal {
  font-size: 14px;
  max-width: 200px;
}

.tabela-descricao {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.tipo-pagamento {
  width: 500px;
}

.receita {
  color: darkblue;
}

.expense {
  color: red;
}
</style>
