<template>
  <div class="principal">
    <Loading :center="false" v-show="loading" />
    <div v-show="!loading">
      <Totalization />
      <ScrollBar :minHeight="300" :maxHeight="300">
        <div>
          <br />
          <table class="table table-responsive-xs">
            <thead>
              <tr>
                <th class="title-header">Data e Hora</th>
                <th class="title-header">Nº Locação</th>
                <th class="title-header">Tipo</th>
                <th class="title-header text-center">Vencimento</th>
                <th class="title-header text-center">Valor</th>
                <th class="title-header text-center">Recebido</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-for="movement in movementList">
              <tr>
                <td class="tabela-principal">
                  {{ movement.registeredDate | moment("DD/MM/YYYY HH:mm") }}
                </td>
                <td class="tabela-principal">
                  {{ movement.numberRent }}
                </td>
                <td class="tabela-principal">
                  {{ movement.typePaymentName }}
                </td>
                <td class="tabela-principal text-center">
                  {{ movement.dueDate | moment("DD/MM/YYYY") }}
                </td>
                <td class="tabela-principal text-center">
                  <span :class="{ receita: movement.typeName == 'Receita' }">
                    {{ movement.value | currency }}</span
                  >
                </td>
                <td class="text-center">
                  <CheckboxSimple v-model="movement.paid" />
                </td>
              </tr>
            </tbody>
            <tbody v-show="movementList.length == 0">
              <tr>
                <td colspan="12">
                  <span>Nenhuma movimentação adicionada!</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ScrollBar>
      <div class="div-totalizarion text-right">
        <TableTotalization :totalization="totalization" />
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading";
import TableTotalization from "@nixweb/nixloc-ui/src/component/shared/TableTotalization";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple";

import Totalization from "./Totalization.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "CashierMovementList",
  props: {
    value: Array,
  },
  components: {
    Loading,
    TableTotalization,
    ScrollBar,
    Totalization,
    CheckboxSimple,
  },
  data() {
    return {
      movementList: [],
      totalization: [],
      urlGet: "/api/v1/cashier/movement/get-all",
      loading: true,
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    getAll(cashierId) {
      this.loading = true;
      let obj = { any: cashierId, noPaginated: true };
      let params = { url: this.urlGet, obj: obj };
      this.getApi(params).then((response) => {
        this.movementList = response.content.data;
        this.totalization = response.content.totalization;
        this.loading = false;
        this.$emit("input", this.movementList);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "movementCashier" || event.name == "_closeCashier") {
          this.getAll(event.data.id);
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.tabela-principal {
  font-size: 14px;
  max-width: 200px;
}

.tabela-descricao {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.title-header {
  font-size: 13px;
  padding-bottom: 5px;
  color: #6c757d;
  text-transform: uppercase;
}

.tabela-principal {
  font-size: 14px;
  max-width: 200px;
}

.tabela-descricao {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.tipo-pagamento {
  width: 500px;
}

.div-totalizarion {
  margin-top: 20px;
  margin-right: 15px;
}

.receita {
  color: darkblue;
}

.expense {
  color: red;
}
</style>
