<template>
  <div>
    <div class="side-by-side div-icon">
      <i class="fa-duotone fa-user"></i>
    </div>
    <div class="side-by-side div-collaborator">
      {{ closeCashier.userName }}
    </div>
    <div>
      <CashierMovementList :cashierId="closeCashier.cashierId" v-model="closeCashier.movementList" />
    </div>

    <b-row>
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <Select title="Categoria" field="category" :formName="formName" :required="true"
          url="api/v1/finance/chart-of-accounts/select-all" :propsParams="{ any: '1' }" :showNewRegister="false"
          v-model="closeCashier.chartOfAccounts">
        </Select>
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="6" xl="6">
        <Select title="Conta Bancária" field="bankAccount" :required="true" :formName="formName"
          v-model="closeCashier.bankAccount" url="api/v1/finance/bank-account/select-all" :showNewRegister="false">
        </Select>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col sm="12">
        <div class="text-center">
          <Button _key="btnCloseCashier" type="success" title="Fechar Caixa" size="medium"
            :disabled="!isFormValid(formName)" :clicked="executeCloseCashier" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Totalization from "./Totalization.vue";
import CashierMovementList from "./CashierMovementList.vue";

import CloseCashier from "@/components/modules/cashier/CloseCashier.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "CloseCashier",
  components: {
    Button,
    Select,
    Totalization,
    CashierMovementList,
  },
  data() {
    return {
      closeCashier: new CloseCashier(),
      formName: "closeCashier",
      urlUpdate: "/api/v1/cashier/close",
      movementList: [],
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["hideModal", "removeLoading"]),
    executeCloseCashier() {
      let params = {
        url: this.urlUpdate,
        obj: this.closeCashier,
        notNotifyToast: true,
      };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.hideModal();
        }
        this.removeLoading(["btnCloseCashier"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "_closeCashier") {
          this.closeCashier.cashierId = event.data.id;
          this.closeCashier.userName = event.data.userName;
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-icon {
  font-size: 18px;
  margin-right: 15px;
}

.div-collaborator {
  font-size: 16px;
}

.div-totalization {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>
