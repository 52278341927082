<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList">
      <div slot="content-buttons">
        <Button
          _key="btnOpenCashier"
          title="Abrir Caixa"
          classIcon="fas fa-plus-circle"
          type="primary"
          size="small"
          :clicked="_openCashier"
        />
      </div>
    </ViewTemplateWithTable>
    <Modal
      title="Movimentações"
      :width="900"
      v-show="showModal('movementCashier')"
    >
      <CashierMovementList />
    </Modal>
    <Modal title="Abrir Caixa" :width="600" v-show="showModal('_openCashier')">
      <OpenCashier urlCreate="/api/v1/cashier/open" />
    </Modal>
    <Modal
      title="Fechar Caixa"
      :width="800"
      v-show="showModal('_closeCashier')"
    >
      <CloseCashier />
    </Modal>
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import OpenCashier from "../../../components/modules/cashier/OpenCashier.vue";
import CloseCashier from "../../../components/modules/cashier/CloseCashier.vue";
import CashierMovementList from "../../../components/modules/cashier/CashierMovementList.vue";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CashierListView",
  components: {
    ViewTemplateWithTable,
    Button,
    Modal,
    CashierMovementList,
    CloseCashier,
    OpenCashier,
  },
  data() {
    return {
      id: "",
      showCloseCashier: false,
      panel: {
        module: "Caixa",
        title: "Caixa",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/cashier/get-all",
        showChecks: false,
        headerTable: [
          {
            title: "Usuário",
            field: "userName",
            type: "text",
            iconSearch: true,
          },
          {
            title: "Data Abertura",
            field: "openDate",
            type: "dateTime",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          /*    {
            title: "Valor Abertura",
            field: "openValue",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },*/
          {
            title: "Data Fechamento",
            field: "closingDate",
            type: "dateTime",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            title: "Valor Fechamento",
            field: "closingValue",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
          {
            title: "Saldo",
            field: "accountBalance",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
          {
            field: "situation",
            title: "Situação",
            type: "class",
            fieldComparison: "situation",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-success",
                fieldComparison: "Aberto",
              },
              {
                classCss: "text-center badge badge-primary",
                fieldComparison: "Fechado",
              },
            ],
          },
          {
            type: "button",
            typeButton: "dropdown",
            classCssBody: "text-center",
            button: {
              title: "Mais...",
              type: "info",
              classIcon: "fa-solid fa-chevron-down",
              size: "small",
              items: [
                {
                  title: "Fechar Caixa",
                  classIcon: "fa-solid fa-cash-register",
                  eventName: "_closeCashier",
                  conditionVisibility: "situation == 'Aberto'",
                  hr: true,
                },
                {
                  title: "Movimentações",
                  classIcon: "fa-solid fa-money-bill-transfer",
                  eventName: "movementCashier",
                  hr: false,
                },
              ],
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
    _openCashier() {
      this.openModal("_openCashier");
      this.removeLoading(["btnOpenCashier"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "movementCashier") {
          this.openModal("movementCashier");
        }
        if (event.name == "_closeCashier") {
          this.openModal("_closeCashier");
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.cashier-open {
  color: #38a169;
}

.cashier-close {
  color: #017aff;
}
</style>